import metadata from './block.json';
import { __ } from '@wordpress/i18n';
import { useEffect, useState } from '@wordpress/element';
import { WtGcBlocksCheckoutWallet } from './walletblock.tsx';
import { CART_STORE_KEY } from '@woocommerce/block-data';
import { useDispatch } from '@wordpress/data';
import { registerCheckoutFilters } from '@woocommerce/blocks-checkout';

// Global import
const { registerCheckoutBlock } = wc.blocksCheckout;


// Arrange the item
setTimeout(function(){  
    let targetElm = document.getElementsByClassName('wp-block-woocommerce-checkout-order-summary-subtotal-block').length ? document.getElementsByClassName('wp-block-woocommerce-checkout-order-summary-subtotal-block') : false;
    if( targetElm && document.getElementById('wt_gc_checkout_store_credit_balance') ){
        targetElm[0].before(document.getElementById('wt_gc_checkout_store_credit_balance'));
    } 
}, 200);

const wbte_gc_hide_wallet_coupon_code = (coupons, params) => {
    var couponsNew = [];
    for ( var k in coupons ) {
        var coupon = coupons[k];
        if ( coupon.code !== params.wt_gc_wallet_coupon_code ) {
            couponsNew.push(coupon);
        } 
    } 

    if ( ! params.wt_gc_wallet_apply_before_tax && ! couponsNew.length ) {
        
        setTimeout(function(){ 
            if ( document.getElementsByClassName('wc-block-components-totals-discount').length ) {
                document.getElementsByClassName('wc-block-components-totals-discount')[0].style.display = 'none';  
            }
        }, 200);
    }           
    return couponsNew;
}

// Hide wallet coupon on cart page
registerCheckoutFilters( 'wt-gc-blocks', {
    coupons: ( coupons, extensions, args ) => {
        let params = ( typeof wc_cart_params !== 'undefined' ? wc_cart_params : null );
        
        if ( params !== null && params.wt_gc_wallet_applied ) {                               
            return wbte_gc_hide_wallet_coupon_code(coupons, params);
        }
        return coupons;
    },
} );


const Block = ({ children, checkoutExtensionData }) => {
    
    const { setExtensionData } = checkoutExtensionData;
    const { applyCoupon, removeCoupon } = useDispatch( CART_STORE_KEY );   
    const [ checkoutWalletData, setCheckoutWalletData ] = useState({ 
        'wallet_amount': wc_checkout_params.wt_gc_wallet_amount, 
        'wallet_amount_html': wc_checkout_params.wt_gc_wallet_amount_html, 
        'wallet_coupon_code': wc_checkout_params.wt_gc_wallet_coupon_code,  
        'wallet_applied': wc_checkout_params.wt_gc_wallet_applied,
        'on_page_load': true
    });

    // Hide wallet coupon on checkout page
    registerCheckoutFilters( 'wt-gc-blocks', {
        coupons: ( coupons, extensions, args ) => {
            let params = ( typeof wc_checkout_params !== 'undefined' ? wc_checkout_params : null );
            
            if ( params !== null ) {               
                return wbte_gc_hide_wallet_coupon_code(coupons, params);
            }
            return coupons;
        },
    } );

    useEffect( () => {

        if ( ! checkoutWalletData.on_page_load ) {

            /* Create custom event */
            const wallet_action_event = new CustomEvent("wt_gc_api_wallet_action", {
                detail:{ 
                    'coupon_code' : checkoutWalletData.wallet_coupon_code,
                    'status' : 'start'
                }
            });

            /* Trigger a custom event */
            setTimeout(function() {
                document.dispatchEvent(wallet_action_event);
            }, 20);
            const context = 'wc/cart'; /* message context */

            if ( checkoutWalletData.wallet_applied ) {
                           
                applyCoupon(checkoutWalletData.wallet_coupon_code)
                    .then((response) => {
                        
                        wallet_action_event.detail.status = 'success';
                        document.dispatchEvent(wallet_action_event);
                    })
                    .catch((error) => { 
                        
                        wallet_action_event.detail.status = 'error';
                        document.dispatchEvent(wallet_action_event);

                        /* Show error message */
                        createErrorNotice(
                            error.message, {
                                id: 'coupon-form',
                                type: 'snackbar',
                                context,
                            }
                        );
                    });
            }else{
               
                removeCoupon(checkoutWalletData.wallet_coupon_code)
                    .then((response) => {
                        
                        wallet_action_event.detail.status = 'success';
                        document.dispatchEvent(wallet_action_event);
                    })
                    .catch((error) => {
                        
                        wallet_action_event.detail.status = 'error';
                        document.dispatchEvent(wallet_action_event); 
                       
                       /* Show error message */
                        createErrorNotice(
                            error.message, {
                                id: 'coupon-form',
                                type: 'snackbar',
                                context,
                            }
                        ); 
                    });
            }
        }

    }, [checkoutWalletData] );

    return (<WtGcBlocksCheckoutWallet 
                checkoutWalletData={ checkoutWalletData }
                setCheckoutWalletData={ setCheckoutWalletData }
            />);
}


const options = {
    metadata,
    component: Block
};

registerCheckoutBlock( options );