import { useCallback } from '@wordpress/element';
import { __, sprintf } from '@wordpress/i18n';
import { useState } from '@wordpress/element';


export const WtGcBlocksCheckoutWallet = ({
    checkoutWalletData,
    setCheckoutWalletData
}) => {

    /* Declare values */
    const { wallet_amount, wallet_amount_html, wallet_coupon_code, wallet_applied } = checkoutWalletData;
    const [ checked, setChecked ] = useState( wallet_applied );

    
    /* Handle checkbox click events  */
    const onUseWalletChange = useCallback(
        ( event ) => {           
            setChecked( event.target.checked );    
            setCheckoutWalletData( {
                ...checkoutWalletData,
                wallet_applied: event.target.checked,
                on_page_load: false
            } );
        },
        [ setChecked, checkoutWalletData, setCheckoutWalletData ]
    ); 

	return ( wallet_amount > 0 ? 
        ( <div className={"wp-block-woocommerce-checkout-order-summary-wt-gc-wallet-block wc-block-components-totals-wrapper"} id={"wt_gc_checkout_store_credit_balance"}>
    		<div className={'wc-block-components-totals-item wt_gc_checkout_store_credit_balance'}>
                <div className={"wc-block-components-totals-item__description"}>
                    <input
                        className="wc-block-components-checkbox__input"
                        type="checkbox"
                        id="wt_gc_block_use_store_credit_balance"
                        name="wt_gc_use_store_credit_balance"
                        checked={ checked } 
                        onChange={ onUseWalletChange }           
                    />
                    <label htmlFor={'wt_gc_block_use_store_credit_balance'}
                        dangerouslySetInnerHTML={ {
                            __html: sprintf(__('Use %s store credit balance', 'wt-woocommerce-gift-cards'), '<b>' + wallet_amount_html + '</b>'),
                        } }
                    />
                </div>     
            </div>
        </div> ) : '' );
}